.App {
  text-align: center;
}


body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


.card {
  min-height: 200px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 40px;
  text-align: center;
}
.card-item {
  background-color: rgba(201, 36, 36, .7);
  border-radius: 15px;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.74);
}
.card .card-img {
  width: 60px;
  margin-top: 15px;
  border-radius: 50%;
}
.card-title {
  color: white;
  font-weight: lighter;
}
.card-role {
  color: #8c8c94;
  font-size: 14px;
}
.card-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn-a,
.btn-b {
  margin-bottom: 10px;
  border: 1px solid #fdfdfd;
  border-radius: 5px;
}
.btn-a {
  background-color: transparent;
  color: #ffffff;
  padding: 5px 25px;
}
.btn-b {
  background-color: #494652;
  color: white;
  padding: 5px 25px;
}
.btn-a:hover,
.btn-b:hover {
  cursor: pointer;
}

@media (max-width: 500px) {
  .card {
    grid-template-columns: 1fr;
  }
}
